.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.main[data-custom-styling=false] > ul > li[data-selected=true] * {
  color: #2012dd;
  font-weight: 600;
}

.main[data-custom-styling=true] {
  background-color: var(--bg-color);
  color: var(--text-color);
}

.main[data-custom-styling=true] {
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
}
.main[data-custom-styling=true] > ul {
  padding-inline-start: 0;
}
.main[data-custom-styling=true] > ul > li {
  display: block;
}
.main[data-custom-styling=true] > ul > li > button {
  width: 100%;
}
.main[data-custom-styling=true] > ul > li {
  padding-top: var(--item-padding-vertical);
  padding-bottom: var(--item-padding-vertical);
  padding-left: var(--item-padding-horizontal);
  padding-right: var(--item-padding-horizontal);
  border-bottom: var(--item-border-style);
}
.main[data-custom-styling=true] > ul > li[data-selected=false] * {
  color: var(--text-color);
}
.main[data-custom-styling=true] > ul > li[data-selected=true] {
  background-color: var(--selected-bg-color);
}
.main[data-custom-styling=true] > ul > li[data-selected=true] * {
  color: var(--selected-text-color);
}

.btn2 {
  text-align: left;
}
